
import './App.css';
import styled, { keyframes } from "styled-components";
import React from 'react';



function App() {
  return (
      <Wrapper>
          
          <Hero>
            <div>Alexander Smirnov</div>
            
           
            <HeroWrapper>I'm CEO from Kazakhstan</HeroWrapper>
            
          </Hero>
          
          <Cirlcle></Cirlcle>
      </Wrapper>
  );
}


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Hero = styled.span`
  font-weight: 800;
  font-size: 45px;
  line-height: 1.4;
  background: linear-gradient(115.87deg, #6F86FE 0%, #C761FF 100%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`  

const animation = keyframes`
  0% { opacity: 0; transform:translateY(200px); filter:blur(10px); }
  25% { opacity: 1; transform:translateY(0); filter:blur(0px); }
  75% { opacity: 1; transform:translateY(0); filter:blur(0px); }
  100% { opacity: 0; transform:translateY(200px); filter:blur(10px); }
`

const HeroWrapper = styled.div`
  color:#fff;
  text-align: center;
  font-weight: 650;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #fff;
  margin: 0;
  opacity: 1;
  font-weight: 600;
  font-size: 32px;
  animation-name: ${animation};
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`

const AboutHero = styled.p`
  color:#fff;
  text-align: center;
  margin: 0;
  font-weight: 600;
  font-size: 40px;
`

const Cirlcle = styled.div`
  position: absolute;
  width: 474px;
  height: 474px;
  border-radius: 50%;
  left: calc(50% - 474px/2);
  top: calc(50% - 474px/2);

  background: linear-gradient(180deg, #9FE1FF 0%, rgba(195, 236, 255, 0.0853208) 50.94%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: overlay;
`




export default App;
